<template>
  <div class="company-activations-categories-tabs">
    <!-- Pestañas de categorías -->
    <template v-if="categories.length">
      <v-tabs fixed-tabs @change="handleChangeTab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="category in categories" :key="category.id">
          {{ category.name }}
          <!-- Delete category -->
          <v-btn
            elevation="0"
            icon
            x-small
            @click="handleDelCategory(category.id)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>
      <!-- Tab content -->
      <CompanyActivationsDishes
        :category-id="selectedItem"
        :place-id="placeId"
      />
    </template>
    <!-- No existen categorías -->
    <template v-else>
      <span class="caption">
        No existe aún ninguna categoría en la carta seleccionada
      </span>
    </template>
  </div>
</template>

<script>
// Components
import CompanyActivationsDishes from './CompanyActivationsDishes'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'CompanyActivationsCategoriesTabs',
  components: { CompanyActivationsDishes },
  props: {
    categories: {
      type: Array,
      default() {
        return []
      }
    },
    placeId: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    return {
      selectedItem: null
    }
  },
  methods: {
    /**
     * Manejador para el cambio de pestaña
     */
    handleChangeTab(value) {
      this.selectedItem =
        !isNil(value) && this.categories[value]
          ? this.categories[value].id || null
          : null
    },
    /**
     * Handle delete category complete
     */
    handleDelCategory(id) {
      this.$emit('onDelCategory', id)
    }
  }
}
</script>
<style lang="scss">
@import '@/theme/variables.scss';
.company-activations-categories-tabs {
  .company-activations-categories-tabs__custom-card {
    & > .v-card {
      padding: 0.5rem 1rem;
      .v-tab {
        position: relative;
        & > .v-btn {
          position: absolute;
          right: 4px;
          top: 14px;
        }
      }
    }
  }
}
</style>
