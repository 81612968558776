// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class Config extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    activators: null,
    allergens: null,
    currencies: null,
    languages: null
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}generalData`)
  }

  // Here you can extend Company with custom methods
}
