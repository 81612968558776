// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class Places extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    color: '#000',
    companies: {}, // Relation object (keys)
    cover: null,
    currency: {
      code: 'EUR',
      decimal_digits: 2,
      name: 'Euro',
      name_plural: 'euros',
      rounding: 0,
      symbol: '€',
      symbol_native: '€'
    },
    currencyPosition: false, // right is default
    email: null,
    extraAddress: null,
    logo: null,
    categories: {}, // Relation object (keys)
    name: null,
    phone1: null,
    phone2: null,
    place: null,
    qrCode: null,
    users: {}, // Relation object (keys)
    url: null
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}places`)
  }

  // Here you can extend Company with custom methods
}
