import Config from '@/firebase/models/config'

const configDb = new Config()

/**
 * Get config by UID
 *
 * @param {string} id - UID config in database
 */
export async function getConfigById(id) {
  const config = await configDb.read(id)
  return config
}

/**
 * Fake function
 */
export function fakeFunction() {
  // TODO
}
