<template>
  <div class="company-activations-dishes">
    <!-- Loading -->
    <div v-if="processingRequest" class="mt-8 mb-8">
      <ContentLoading :loading="processingRequest" />
    </div>
    <!-- Content -->
    <template v-if="!processingRequest">
      <!-- Allergens options in dialog -->
      <CustomDialog
        :content-component="getAllergensComponent"
        title="Alérgenos"
        :visible="allergensOptionsDialog"
        :hide-action-buttons="true"
        @onClickAcceptButton="handleToggleAllergensDialog"
        @onEventComponent="handleAllergensEventComponent"
      />
      <!-- Prices options in dialog -->
      <CustomDialog
        :content-component="getPricesComponent"
        :content-component-props="getPricesComponentProps"
        title="Precios"
        :visible="pricesOptionsDialog"
        :hide-action-buttons="true"
        @onClickAcceptButton="handleTogglePricesDialog"
        @onEventComponent="handlePricesEventComponent"
      />
      <!-- Category products -->
      <v-form v-if="$v.formFields.dishes" novalidate>
        <v-container>
          <!-- Actions -->
          <v-row v-if="categoryId" dense class="wrapper-actions-dishes">
            <v-col
              cols="1"
              class="d-flex flex-column justify-center align-center"
            >
              <!-- Check every item -->
              <v-checkbox v-model="mainCheckbox" class="mt-0" hide-details />
            </v-col>
            <v-col cols="11" class="actions-dishes">
              <!-- Actions -->
              <v-btn
                color="primary"
                class="mr-1"
                elevation="0"
                small
                :disabled="selectedItems.length === 0"
                @click="handleToggleAllergensDialog"
                >Alérgenos</v-btn
              >
              <v-btn
                color="primary"
                elevation="0"
                small
                :disabled="selectedItems.length === 0"
                @click="handleTogglePricesDialog"
                >Precios</v-btn
              >
            </v-col>
          </v-row>
          <!-- Items -->
          <v-row
            v-for="(dish, index) in $v.formFields.dishes.$each.$iter"
            :key="`dish-${categoryId}-${index}`"
            dense
            class="dish"
          >
            <!-- id -->
            <v-col
              cols="1"
              class="d-flex flex-column justify-center align-center"
            >
              <v-checkbox
                v-model="selectedItems"
                class="mt-0"
                :value="dish.id.$model"
                :hide-details="true"
              />
            </v-col>
            <v-col cols="5">
              <v-row dense>
                <v-col cols="12">
                  <!-- name -->
                  <v-text-field
                    v-model="dish.name.$model"
                    label="Nombre"
                    outlined
                    dense
                    :error-messages="
                      checkFieldErrors(`dishes.$each[${index}].name`)
                    "
                    :hide-details="
                      !checkFieldErrors(`dishes.$each[${index}].name`).length
                    "
                    @input="touchField(`dishes.$each[${index}].name`)"
                    @blur="touchField(`dishes.$each[${index}].name`)"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <!-- description -->
                  <v-textarea
                    v-model="dish.description.$model"
                    rows="3"
                    label="Descripción"
                    outlined
                    dense
                    :error-messages="
                      checkFieldErrors(`dishes.$each[${index}].description`)
                    "
                    :hide-details="
                      !checkFieldErrors(`dishes.$each[${index}].description`)
                        .length
                    "
                    @input="touchField(`dishes.$each[${index}].description`)"
                    @blur="touchField(`dishes.$each[${index}].description`)"
                  />
                </v-col>
              </v-row>
            </v-col>
            <!-- allergens -->
            <v-col
              cols="2"
              class="d-flex flex-column justify-center align-center text-center"
            >
              <AllergensIcons :allergens="dish.allergens.$model || []" />
            </v-col>
            <!-- prices -->
            <v-col
              cols="3"
              class="d-flex flex-column justify-center align-center text-center"
            >
              <PricesInputs
                :inputs="dish.prices.$model || {}"
                :category="categoryId"
              />
            </v-col>
            <!-- actions -->
            <v-col
              cols="1"
              class="d-flex flex-column justify-center align-center"
            >
              <v-btn
                elevation="0"
                icon
                x-small
                @click="handleDelDish(dish.id.$model)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- footer actions -->
          <v-row>
            <v-col class="text-center mt-2">
              <v-btn color="primary" elevation="0" small @click="handleAddDish"
                >Añadir producto</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </div>
</template>

<script>
// Components
import AllergensOptions from '@/components/elements/dishes/AllergensOptions'
import PricesOptions from '@/components/elements/dishes/PricesOptions'
import AllergensIcons from '@/components/elements/dishes/AllergensIcons'
import PricesInputs from '@/components/elements/dishes/PricesInputs'
import CustomDialog from '@/components/ui/CustomDialog'
import ContentLoading from '@/components/ui/ContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import {
  delDishById,
  updateSomeDishes,
  createSomeDishes,
  getEveryDishesByCategory
} from '@/services/dish'
import { getCategoryById } from '@/services/category'
// Utils
import { cloneDeep, debounce, get, set } from 'lodash'

export default {
  name: 'CompanyActivationsDishes',
  components: { CustomDialog, AllergensIcons, PricesInputs, ContentLoading },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Place
    placeId: {
      type: String,
      default: null,
      required: true
    },
    // Category
    categoryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Form
      formFields: {
        dishes: []
      },
      formFieldsValidations: {
        dishes: {
          id: {
            required: 'Campo obligatorio'
          },
          name: {
            required: 'Campo obligatorio'
          },
          description: {},
          allergens: {},
          prices: {}
        }
      },
      // Allergens & Rations (Dialog open or close)
      allergensOptionsDialog: false,
      pricesOptionsDialog: false,
      // Others
      mainCheckbox: false,
      selectedItems: [],
      processingRequest: true
    }
  },
  computed: {
    /**
     * Get the component to show the allergens options
     */
    getAllergensComponent() {
      return AllergensOptions
    },
    /**
     * Get the component to show the prices options
     */
    getPricesComponent() {
      return PricesOptions
    },
    /**
     * Get the component props to use in component
     */
    getPricesComponentProps() {
      return {
        placeId: this.placeId
      }
    }
  },
  watch: {
    /**
     * Cargamos los platos de la categoría seleccionada
     */
    categoryId(value) {
      if (value) {
        this.setDishes(value)
      }
    },
    /**
     * Cuando el checkbox "general" modifica su valor (true o false),
     * debes "marcar" o "desmarcar" el resto de items
     */
    mainCheckbox(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.selectedItems = this.formFields.dishes.map(dish => {
            return dish.id
          })
        } else {
          this.unSelectedItems()
        }
      }
    },
    /**
     * Cuando hay modificaciones en los campos, salvamos los datos
     */
    formFields: {
      deep: true,
      handler: debounce(function debouceSubmit() {
        this.onSubmit()
      }, 1000)
    }
  },
  updated() {
    this.setObserverToActionDishes()
  },
  methods: {
    /**
     * Toggle allergens dialog
     */
    handleToggleAllergensDialog() {
      this.allergensOptionsDialog = !this.allergensOptionsDialog
    },
    /**
     * Toggle rations dialog
     */
    handleTogglePricesDialog() {
      this.pricesOptionsDialog = !this.pricesOptionsDialog
    },
    /**
     * Handle event from component loaded
     *
     * @param {array} allergens - allergens ids
     */
    handleAllergensEventComponent(allergens) {
      // Hide dialog
      this.handleToggleAllergensDialog()
      // Set allergens inside each item (dish)
      this.selectedItems.forEach(item => {
        const itemIndex = this.formFields.dishes.findIndex(dish => {
          return dish.id === item
        })

        if (itemIndex > -1) {
          this.formFields.dishes[itemIndex].allergens = allergens
        }
      })
      // Deseleccionamos los checkboxs
      this.unSelectedItems()
    },
    /**
     * Handle event from component loaded
     *
     * @param {object} prices - price object
     */
    handlePricesEventComponent(prices) {
      // Hide dialog
      this.handleTogglePricesDialog()
      // Set prices inside each item (dish)
      this.selectedItems.forEach(item => {
        const itemIndex = this.formFields.dishes.findIndex(dish => {
          return dish.id === item
        })

        if (itemIndex > -1) {
          // Si NO existe el atributo "prices" (generamos reactivadad en Vue)
          if (!get(this.formFields, `dishes.${itemIndex}.prices`, false)) {
            this.$set(this.formFields.dishes[itemIndex], 'prices', {})
          }

          // Si NO existe la categoría dentro del precios
          // producto (generamos reactivadad en Vue)
          if (
            !get(
              this.formFields,
              `dishes.${itemIndex}.prices.${this.categoryId}`,
              false
            )
          ) {
            this.$set(
              this.formFields.dishes[itemIndex].prices,
              this.categoryId,
              cloneDeep(prices)
            )
          } else {
            set(
              this.formFields,
              `dishes.${itemIndex}.prices.${this.categoryId}`,
              cloneDeep(prices)
            )
          }
        }
      })
      // Deseleccionamos los checkboxs
      this.unSelectedItems()
    },
    /**
     * Handle add dish to list
     */
    async handleAddDish() {
      const dishes = await createSomeDishes(
        'Producto',
        this.categoryId,
        1,
        this.formFields.dishes.length
      )

      dishes.forEach(dish => {
        this.formFields.dishes.push(dish)
      })
    },
    /**
     * Handle delete list's dish
     */
    handleDelDish(id) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Buscamos el indice del elemento a eliminar
          const dishIndex = this.formFields.dishes.findIndex(dish => {
            return dish.id === id
          })

          if (dishIndex > -1) {
            // Eliminamos el elemento de la lista
            this.formFields.dishes.splice(dishIndex, 1)
            // Eliminamos plato de la BD
            await delDishById(id)
            // Siempre que elimina algún producto,
            // se marcan los productos que le siguen
            // no tengo ni idea de el motivo de esto,
            // para evitar dicho error, realizo esta llamada
            this.unSelectedItems()
          }
        },
        actionButtonText: 'Borrar',
        dismissibleTimeOut: -1,
        text: '¿Desea borrar el producto?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Deseleccionamos todos los items
     */
    unSelectedItems() {
      // Desmarcamos el "checkbox" padre
      this.mainCheckbox = false
      // Deseleccionamos todos los checkbox "hijos"
      this.selectedItems = []
    },
    /**
     * Establecemos los platos de la categoría
     * donde nos encontramos
     *
     * @param {string} id - UID category
     */
    async setDishes(id) {
      try {
        this.processingRequest = true
        const categoryData = await getCategoryById(id)
        const dishesData = await getEveryDishesByCategory(categoryData.id)

        this.$set(this.formFields, 'dishes', dishesData)
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Observamos el elemento "action-dishes"
     */
    setObserverToActionDishes() {
      // Elementos a observar
      const wrapper = document.querySelector('.wrapper-actions-dishes')
      const actions = document.querySelector('.actions-dishes')
      // Manejador del callback del observador
      const handleObserver = entries => {
        const IsShowingActionDishes = entries.every(entry => {
          return entry.isIntersecting
        })
        !IsShowingActionDishes
          ? actions.classList.add('fixed')
          : actions.classList.remove('fixed')
      }
      const observer = new IntersectionObserver(handleObserver, {
        rootMargin: '0px 0px 0px 0px',
        threshold: 1.0
      })

      if (wrapper) {
        // Eliminamos la observación inicial
        observer.unobserve(wrapper)
        // Observamos el elemento
        observer.observe(wrapper)
      }
    },
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        await updateSomeDishes(this.formFields.dishes)
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      dishes: {
        $each: {
          id: {
            required
          },
          name: {
            required
          },
          description: {},
          allergens: {},
          prices: {}
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/theme/variables.scss';
.company-activations-dishes {
  .row {
    &.wrapper-actions-dishes {
      padding: 1rem 4px;
      .actions-dishes {
        &.fixed {
          position: fixed;
          z-index: 2;
          top: 64px;
          left: 50%;
          width: auto;
          padding: 0.5rem;
          margin-left: -108.5px;
          background-color: #fff;
        }
      }
    }
    &.dish:nth-child(even) {
      background-color: $theme_color_light;
    }
  }
}
</style>
