import { render, staticRenderFns } from "./AllergensIcons.vue?vue&type=template&id=39ba4b34&scoped=true&"
import script from "./AllergensIcons.vue?vue&type=script&lang=js&"
export * from "./AllergensIcons.vue?vue&type=script&lang=js&"
import style0 from "./AllergensIcons.vue?vue&type=style&index=0&id=39ba4b34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ba4b34",
  null
  
)

export default component.exports