<template>
  <div class="allergens-icons">
    <!-- list allergens -->
    <template v-if="allergens.length">
      <div class="list-items">
        <img
          v-for="id in allergens"
          :key="id"
          class="icon"
          :src="getPathAllergenIcon(id)"
        />
      </div>
    </template>
    <template v-else>
      <span class="text-center caption">Sin alérgenos</span>
    </template>
  </div>
</template>

<script>
// Services
import { getConfigById } from '@/services/config'
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'AllergensIcons',
  props: {
    allergens: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      allergensData: null
    }
  },
  async created() {
    await this.setAllergensData()
  },
  methods: {
    /**
     * Set the available allergens
     */
    async setAllergensData() {
      const { data } = await getConfigById('allergens')
      this.allergensData = data
    },
    /**
     * Get icon to draw allergen
     *
     * @param {string} id
     * @return {string} - path to icon
     */
    getPathAllergenIcon(id) {
      const currentIndex = this.getIndexAllergensById(id)

      if (currentIndex > -1) {
        const currentAllergen = { ...this.allergensData[currentIndex] }
        // Remove "img" from it is not neccesary and '"'
        let pathIcon = currentAllergen.icon.replace(/\/img\//i, '')
        pathIcon = pathIcon.replace(/"/gim, '')
        return getPathImage(pathIcon)
      }

      return false
    },
    /**
     * Get index from allergens array by Id allergen
     *
     * @param {string} id - id allergens array
     */
    getIndexAllergensById(id) {
      const allergensData = this.allergensData
        ? Object.entries(this.allergensData)
        : false

      return allergensData && Array.isArray(allergensData)
        ? allergensData.findIndex(allergen => {
            return allergen[1].id === Number.parseInt(id, 10)
          })
        : -1
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.allergens-icons {
  width: 100%;
  .list-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    .icon {
      width: 20px;
      height: auto;
      margin: 0 2px 2px 0;
    }
  }
}
</style>
