// Constans
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class MetaField extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    metas: {}, // asociado al "meta" dato
    name: null
  }

  // Translate (optional)
  fieldsToTranslate = [
    {
      id: 'name',
      label: 'Nombre',
      type: 'text'
    }
  ]

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}metaFields`)
  }

  // Here you can extend Company with custom methods
}
