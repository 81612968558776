<template>
  <div class="company-activations-menus">
    <div class="text-subtitle1 mb-2">Cartas del establecimiento</div>
    <CustomCard class="company-activations-menus__custom-card mb-4">
      <!-- Formulario -->
      <v-form novalidate @submit.prevent="onSubmit">
        <v-row>
          <v-col cols="12" md="3" sm="3">
            <!-- name -->
            <v-text-field
              v-model="$v.formFields.name.$model"
              label="Carta"
              dense
              outlined
              :error-messages="checkFieldErrors('name')"
              :hide-details="!checkFieldErrors('name').length"
              @input="touchField('name')"
              @blur="touchField('name')"
            />
          </v-col>
          <v-col cols="12" md="5" sm="5">
            <!-- price -->
            <v-text-field
              v-model="$v.formFields.description.$model"
              label="Descripción"
              type="text"
              dense
              outlined
              :error-messages="checkFieldErrors('description')"
              :hide-details="!checkFieldErrors('description').length"
              @input="touchField('description')"
              @blur="touchField('description')"
            />
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <!-- price -->
            <v-text-field
              v-model="$v.formFields.price.$model"
              label="Precio"
              type="number"
              dense
              outlined
              :error-messages="checkFieldErrors('price')"
              :hide-details="!checkFieldErrors('price').length"
              @input="touchField('price')"
              @blur="touchField('price')"
            />
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <FormButtons
              :accept-button-v-options="saveButtonVOptions"
              align-buttons="center"
              accept-button-text="Guardar"
              :accept-button-loading="formProcessing"
              cancel-button-hide
            />
          </v-col>
        </v-row>
      </v-form>
      <v-divider />
      <!-- Selector -->
      <v-row>
        <v-col
          cols="12"
          :md="selectedMenu ? 9 : 12"
          :sm="selectedMenu ? 9 : 12"
        >
          <v-select
            v-model="selectedMenu"
            :items="menus"
            item-value="id"
            item-text="name"
            dense
            label="Elige la carta que deseas editar"
            :hide-details="true"
            outlined
          />
        </v-col>
        <v-col v-if="selectedMenu" cols="12" md="3" sm="3">
          <FormButtons
            :accept-button-v-options="editButtonVOptions"
            :cancel-button-v-options="removeButtonVOptions"
            accept-button-text="Editar"
            accept-button-type="button"
            cancel-button-text="Eliminar"
            :accept-button-loading="formProcessing"
            @onClickAcceptButton="handleEditButton"
            @onClickCancelButton="handleRemoveButton"
          />
        </v-col>
      </v-row>
    </CustomCard>
  </div>
</template>

<script>
// Components
import CustomCard from '@/components/ui/CustomCard'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import {
  createCategory,
  updateCategory,
  getCategoryById,
  getEveryCategoriesByIds,
  delMenuById
} from '@/services/category'
import { getPlaceById, updatePlace } from '@/services/place'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'CompanyActivationsMenus',
  components: {
    CustomCard,
    FormButtons
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    placeId: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    return {
      // Form
      formFields: {
        name: null,
        description: null,
        price: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        price: {}
      },
      // Others
      categoryData: null,
      menus: [],
      selectedMenu: null
    }
  },
  computed: {
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    saveButtonVOptions() {
      return {
        block: true,
        color: 'primary'
      }
    },
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    editButtonVOptions() {
      return {
        color: 'primary'
      }
    },
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    removeButtonVOptions() {
      return {
        color: 'error'
      }
    }
  },
  watch: {
    selectedMenu(value) {
      this.$emit('onChangeMenu', value)
      // Eliminamos datos almacenados de edición
      this.resetForm()
    }
  },
  async mounted() {
    this.setEveryNeededData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        dismissibleTimeOut: -1,
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Cuando pulsamos en el botón de "Editar"
     */
    async handleEditButton() {
      this.formProcessing = true
      try {
        // Obtenemos datos de la carta
        this.categoryData = await getCategoryById(this.selectedMenu)
        // Establecemos datos de la categoría obtenida
        this.formFields.name = this.categoryData.name
        this.formFields.description = this.categoryData.description
        this.formFields.price = this.categoryData.price
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Cuando pulsamos en el botón de eliminar
     */
    handleRemoveButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            // Eliminamos carta, categorías y productos asociados
            await delMenuById(this.selectedMenu)
            // Eliminamos carta del selector
            this.menus = this.menus.filter(menu => {
              return menu.id !== this.selectedMenu
            })
            // Deseleccionamos el menú seleccionado
            this.selectedMenu = null
          } catch (error) {
            this.handleError(error.message)
          } finally {
            this.formProcessing = false
          }
        },
        actionButtonText: 'Borrar',
        dismissibleTimeOut: -1,
        text: '¿Desea borrar el menú o carta seleccionado?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async setEveryNeededData() {
      this.menus = await this.getEveryMenus(this.placeId)
    },
    /**
     * Obtiene los distintas cartas que posee un establecimiento
     *
     * @param {String} id - UID establecimiento
     * @return {Array}
     */
    async getEveryMenus(id) {
      const placeData = await getPlaceById(id)
      if (
        placeData &&
        placeData.categories &&
        Object.keys(placeData.categories).length > 0
      ) {
        const menusData = await getEveryCategoriesByIds(
          Object.keys(placeData.categories)
        )

        return menusData.map(menu => {
          return {
            id: menu.id,
            name: menu.name
          }
        })
      }

      return []
    },
    /**
     * Reset current formulary
     */
    resetForm() {
      // Reset Form fields
      this.formFields = {
        name: null,
        description: null,
        price: null
      }
      // Eliminamos datos almacenados de edición
      this.categoryData = null
      // Reset validaciones
      this.$v.$reset()
    },
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      this.formProcessing = true
      try {
        // Creamos nueva carta
        if (isNil(this.categoryData)) {
          const menu = await createCategory({
            name: this.formFields.name,
            description: this.formFields.description,
            price: this.formFields.price
          })
          // Incluimos la carta en el listado del selector
          this.menus.push({
            id: menu.id,
            name: menu.name
          })
          // Añadimos nueva carta al establecimiento
          await updatePlace({
            id: this.placeId,
            categories: this.menus.reduce((sumMenus, menu) => {
              sumMenus[menu.id] = true
              return sumMenus
            }, {})
          })
        } else {
          // Editamos categoría (menú)
          await updateCategory({
            id: this.selectedMenu,
            name: this.formFields.name,
            description: this.formFields.description,
            price: this.formFields.price
          })

          // Buscamos en el listado de "menus", dicho menú
          // y modificamos los datos
          this.menus = this.menus.map(menu => {
            return {
              id: menu.id,
              name:
                menu.id === this.selectedMenu ? this.formFields.name : menu.name
            }
          })
        }
        // Resetear formulario
        this.resetForm()
        // Show info
        this.modifyAppAlert({
          text: 'La carta se guardó correctamente',
          visible: true
        })
      } catch (error) {
        // Show error
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      name: {
        required
      },
      description: {},
      price: {}
    }
  }
}
</script>
<style lang="scss">
@import '@/theme/variables.scss';
.company-activations-menus {
  .company-activations-menus__custom-card {
    & > .v-card {
      padding: 0.5rem 1rem;
    }
  }
}
</style>
