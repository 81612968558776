import slugify from 'slugify'
import Place from '@/firebase/models/place'

const placeDb = new Place()

/**
 * Get place by UID
 *
 * @param {string} id - UID place in database
 */
export async function getPlaceById(id) {
  const place = await placeDb.read(id)
  return place
}

/**
 * Get place by UID
 *
 * @param {string} id - UID place in database
 */
export async function getPlaceByIdOrUrl(url) {
  let place = await placeDb.readAll({
    constraints: [['url', '==', url]],
    limit: 1
  })
  place = place.length > 0 ? place[0] : null
  if (!place) {
    place = await getPlaceById(url)
  }

  return place
}

/**
 * Create new place
 *
 * @param {object} data - place data
 */
export const createPlace = async (data, id = null) => {
  const place = await placeDb.create(data, id)
  return place
}

/**
 * Update place in database
 *
 * @param {object} data - place data
 */
export const updatePlace = async data => {
  const place = await placeDb.update(data)
  return place
}

/**
 * Genera la url del place a partir del nombre de compañia
 *
 * @param {string} companyName
 */
export const getUrlPlace = async companyName => {
  try {
    const url = slugify(companyName, '-', { lower: true })

    let i = 0
    let places = null
    let urlNew = null

    do {
      urlNew = `${url}${i}`
      if (i === 0) urlNew = url
      places = await placeDb.readAll({
        constraints: [['url', '==', urlNew]],
        limit: 1
      })
      i += 1
    } while (places && places.length > 0 && i < 100)

    return urlNew
  } catch (error) {
    throw new Error('No se ha podido generar la url')
  }
}

/**
 * Get place by companyKeys id
 *
 * @param {string} id - companyKeys id
 * @return {object} - place data (promise)
 */
export async function getPlaceByCompanyKeysId(id) {
  const places = await placeDb.readAll({
    constraints: [['companyKeysId', '==', id]],
    limit: 1
  })

  if (places && Array.isArray(places) && places.length) {
    return places.shift()
  }

  return false
}
