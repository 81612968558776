<template>
  <div class="company-activations-preview">
    <!-- botón de abrir enlace a carta -->
    <template v-if="url">
      <h3 class="mb-2">Enlace</h3>
      <v-btn small class="mb-2 secondary" :href="url" target="_blank">
        <v-icon class="mr-1">mdi-open-in-new</v-icon>Abrir
      </v-btn>
    </template>
    <!-- botones de descarga de imágenes -->
    <template v-if="!url && images && images.length">
      <h3>Imágenes</h3>
      <v-btn
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        small
        class="mr-2 secondary"
        :href="image"
        target="_blank"
      >
        <v-icon>mdi-download</v-icon>
        Img {{ imageIndex }}
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CompanyActivationsPreview',
  props: {
    images: {
      type: Array,
      default() {
        return []
      }
    },
    url: {
      type: String,
      default: ''
    }
  }
}
</script>
