<template>
  <div class="prices-options">
    <!-- Price Type -->
    <v-row>
      <v-col class="text-center">
        <v-btn-toggle
          v-model="currentPriceTypes"
          color="primary"
          @change="handleCurrentPriceTypes"
        >
          <v-btn v-for="type in priceTypes" :key="type.id" :value="type.id">
            {{ type.label }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <!-- Price options -->
    <template
      v-if="
        currentPriceTypes === 2 &&
          priceOptions &&
          Object.keys(priceOptions).length
      "
    >
      <v-row v-for="option in priceOptions" :key="option.id">
        <v-col cols="1" class="d-flex flex-column justify-center align-center">
          <v-checkbox
            v-model="selectedPriceOptions"
            class="mt-0"
            :value="option.id"
            :hide-details="true"
          />
        </v-col>
        <v-col cols="7">
          <v-text-field
            v-model="option.name"
            label="Nombre"
            :hide-details="true"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model.number="option.price"
            label="Precio por defecto"
            :hide-details="true"
            type="number"
          />
        </v-col>
      </v-row>
    </template>
    <!-- Add new price option (ration) -->
    <template v-if="currentPriceTypes === 2">
      <v-row>
        <v-col class="text-center mt-2">
          <v-btn color="primary" elevation="0" small @click="handleAddRation">
            Nuevo tipo de ración
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <!-- action buttons -->
    <FormButtons
      accept-button-text="Seleccionar"
      :cancel-button-hide="true"
      @onClickAcceptButton="handleAcceptButton"
    />
  </div>
</template>

<script>
// Services
import {
  createRation,
  getDefaultRations,
  getMetaRationByPlaceId,
  getRationsByPlaceId,
  updateMetaRation
  // createRation
} from '@/services/ration'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Utils
import { isNil } from 'lodash'

const uniqid = require('uniqid')

export default {
  name: 'PricesOptions',
  components: { FormButtons },
  props: {
    // Place
    placeId: {
      default: null,
      type: String,
      required: true
    }
  },
  data() {
    return {
      // kind of price
      currentPriceTypes: 0,
      priceTypes: [
        {
          id: 0,
          label: 'Sin precio'
        },
        {
          id: 1,
          label: 'Precio único'
        },
        {
          id: 2,
          label: 'Por ración'
        }
      ],
      // option prices
      priceOptions: null,
      // Others
      selectedPriceOptions: []
    }
  },
  methods: {
    /**
     * Handle event when the price type changes
     *
     * @param {number} type - price type
     */
    async handleCurrentPriceTypes(type) {
      if (type === 0) {
        // Sin precio
        this.priceOptions = null
      } else if (type === 1) {
        // Precio único
        this.priceOptions = await this.getPlaceRations('default')
      } else if (type === 2) {
        // Por tipo de tapa
        this.priceOptions = await this.getPlaceRations('custom')
      }
    },
    /**
     * Handle add ration to list
     */
    async handleAddRation() {
      const metaRation = await getMetaRationByPlaceId(this.placeId)

      if (metaRation) {
        this.priceOptions.push({
          id: uniqid(),
          name: 'Nombre ración',
          metas: { [metaRation.id]: true },
          order: 0,
          price: null,
          new: true
        })
      }
    },
    /**
     * Event triggered when click on "accept" button
     */
    async handleAcceptButton() {
      if (this.currentPriceTypes === 2) {
        // Meta-racion genérica
        const { id, metaFields } = await getMetaRationByPlaceId(this.placeId)
        // Salvamos los nuevos precios en BD
        const newRations = await Promise.all(
          this.priceOptions
            .filter(price => {
              return price.new
            })
            .map(async (price, index) => {
              delete price.new
              const { id, ...data } = price
              const ration = await createRation(
                { ...data, order: this.priceOptions.length + index },
                this.placeId,
                id
              )
              return ration
            })
        )

        // Id de las raciones
        newRations.reduce((sumRationsIds, ration) => {
          sumRationsIds[ration.id] = true
          return sumRationsIds
        }, metaFields)

        // Actualizamos meta-ración padre
        await updateMetaRation({ id, metaFields })

        // Only pass the selected options
        if (this.selectedPriceOptions.length) {
          this.priceOptions = this.priceOptions.reduce((sumPrices, price) => {
            if (this.selectedPriceOptions.indexOf(price.id) > -1) {
              sumPrices.push({
                id: price.id,
                name: price.name,
                price: price.price,
                order: price.order
              })
            }
            return sumPrices
          }, [])
        } else {
          this.priceOptions = null
        }
      }

      // Este evento es lanzado para todo aquel componente
      // que ha sido incluido bajo el component "CustomDialog"
      this.$emit(
        'onEventComponent',
        this.parsePriceOptionsToObject(this.priceOptions)
      )
      // Reset everything
      this.selectedPriceOptions = []
      this.currentPriceTypes = 0
      this.priceOptions = null
    },
    /**
     * Get the rations associated to the company
     *
     * @param {string} rationsType - "default", "custom"
     * @return {array} - rations array
     */
    async getPlaceRations(rationsType = 'default') {
      const customRations = await getRationsByPlaceId(this.placeId)
      const defaultRations = await getDefaultRations()

      if (rationsType === 'default') {
        return defaultRations
      }
      if (rationsType === 'custom') {
        return customRations
      }

      return []
    },
    /**
     * Parse "priceOptions" from array to object
     *
     * @param {object} priceOptions - prices (rations) values
     * @return {object} - object to save in dish model
     */
    parsePriceOptionsToObject(priceOptions) {
      if (!isNil(priceOptions)) {
        return priceOptions.reduce((sumRations, price) => {
          const { id, ...last } = price
          sumRations[id] = { ...last }

          return sumRations
        }, {})
      }

      return priceOptions
    }
  }
}
</script>
