<template>
  <div>
    <h3>Solicitud</h3>
    <div v-if="companyActivation" class="mb-5">
      <p>
        <b>Id:</b>
        {{ companyActivation.id }}
        <br />
        <b>Usuario:</b>
        {{ companyActivation.userName }}
        <br />
        <b>Empresa:</b>
        {{ companyActivation.companyName }}
        <br />
        <b>Dirección:</b>
        {{ companyActivation.address }}
        <br />
        <b>CP:</b>
        {{ companyActivation.postalCode }}
        <br />
        <b>Email:</b>
        {{ companyActivation.email }}
        <br />
        <b>Teléfono:</b>
        {{ companyActivation.phone }}
      </p>
      <p>
        <b>Nombre del comercial:</b>
        {{ companyActivation.commercialName }}
        <br />
        <b>Email del comercial:</b>
        {{ companyActivation.commercialEmail }}
        <br />
        <b>Observaciones:</b>
        {{ companyActivation.supportText }}
      </p>
    </div>
    <h3>Company Key</h3>
    <div v-if="companyKey">
      <p>
        <b>Id:</b>
        {{ companyKey.id }}
        <br />
        <b>Área:</b>
        {{ companyKey.area }}
        <br />
        <b>Gerencia:</b>
        {{ companyKey.management }} - {{ companyKey.managementName }}
        <br />
        <b>Promotor:</b>
        {{ companyActivation.promoter }}
        <br />
        <b>Ruta:</b>
        {{ companyActivation.route }}
        <br />
      </p>
    </div>
  </div>
</template>
<script>
// Services
import { getPanelCompanyActivationById } from '@/services/panelCompanyActivations'
import { getCompanyKeysById } from '@/services/companyKeys'
export default {
  name: 'InfoActivationCompany',
  data() {
    return {
      companyActivation: null,
      companyKey: null
    }
  },
  async mounted() {
    this.companyActivation = await getPanelCompanyActivationById(
      this.$route.params.id
    )
    this.companyKey = await getCompanyKeysById(
      this.companyActivation.companyKeysId
    )
  }
}
</script>
