<template>
  <div class="company-activations-categories">
    <div class="text-subtitle1 mb-2">
      Categorías de la carta seleccionada
    </div>
    <CustomCard class="company-activations-categories__custom-card mb-4">
      <!-- Categorie form -->
      <v-form novalidate @submit.prevent="onSubmit">
        <v-row>
          <v-col cols="12" md="8" sm="8">
            <!-- name -->
            <v-text-field
              v-model="$v.formFields.name.$model"
              label="Categoría"
              dense
              outlined
              :error-messages="checkFieldErrors('name')"
              :hide-details="!checkFieldErrors('name').length"
              @input="touchField('name')"
              @blur="touchField('name')"
            />
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <!-- products number -->
            <v-text-field
              v-model="$v.formFields.numProducts.$model"
              label="Productos"
              type="number"
              dense
              outlined
              :error-messages="checkFieldErrors('numProducts')"
              :hide-details="!checkFieldErrors('numProducts').length"
              @input="touchField('numProducts')"
              @blur="touchField('numProducts')"
            />
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <FormButtons
              :accept-button-v-options="acceptButtonVOptions"
              align-buttons="center"
              accept-button-text="Crear"
              :accept-button-loading="formProcessing"
              cancel-button-hide
            />
          </v-col>
        </v-row>
      </v-form>
      <v-divider />
      <v-row>
        <v-col>
          <!-- Categories and products -->
          <CompanyActivationsCategoriesTabs
            :place-id="placeId"
            :categories="categories"
            @onDelCategory="handleDelCategory"
          />
        </v-col>
      </v-row>
    </CustomCard>
  </div>
</template>

<script>
// Components
import CustomCard from '@/components/ui/CustomCard'
import FormButtons from '@/components/ui/FormButtons'
import CompanyActivationsCategoriesTabs from '@/components/elements/companyActivations/CompanyActivationsCategoriesTabs'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
// Services
import {
  createCategory,
  delCategoryById,
  getCategoriesByParentId
} from '@/services/category'
import { createSomeDishes } from '@/services/dish'

export default {
  name: 'CompanyActivationsCategories',
  components: {
    CustomCard,
    FormButtons,
    CompanyActivationsCategoriesTabs
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    menuId: {
      type: String,
      default: null
    },
    placeId: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    return {
      // categories
      categories: [],
      // Form
      formFields: {
        name: null,
        numProducts: 10
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        numProducts: {
          numeric: 'Debe ser numérico'
        }
      }
    }
  },
  computed: {
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        block: true,
        color: 'primary'
      }
    }
  },
  async mounted() {
    await this.setEveryNeededData()
  },
  watch: {
    menuId(value) {
      this.setCategories(value)
    }
  },
  methods: {
    /**
     * Handle delete category and dishes
     *
     * @param {string} id - id category in DB
     */
    async handleDelCategory(id) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Remove list's item
          this.categories = this.categories.filter(item => {
            return item.id !== id
          })
          // Remove in DB
          await delCategoryById(id)
        },
        actionButtonText: 'Borrar',
        dismissibleTimeOut: -1,
        text: '¿Desea borrar la categoría y sus productos?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async setEveryNeededData() {
      await this.setCategories(this.menuId)
    },
    /**
     * Get the categories associated to menuId
     *
     * @param {string} id - UID category
     * @return {array} - categories associated
     */
    async setCategories(id) {
      const categories = await getCategoriesByParentId(id)
      this.categories = categories.map(category => {
        return {
          id: category.id,
          name: category.name
        }
      })
    },
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        // Create new categorie
        const category = await createCategory({
          name: this.formFields.name,
          parentId: this.menuId,
          order: this.categories.length
        })

        // Create new dishes associated to this category
        await createSomeDishes(
          'Producto',
          category.id,
          this.formFields.numProducts
        )
        // Add to categories
        this.categories.push({ id: category.id, name: this.formFields.name })
        // Reset Form fields
        this.formFields = {
          name: null,
          numProducts: 10
        }
        // Reset validaciones
        this.$v.$reset()
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      name: {
        required
      },
      numProducts: {
        numeric
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/theme/variables.scss';
.company-activations-categories {
  .company-activations-categories__custom-card {
    & > .v-card {
      padding: 0.5rem 1rem;
    }
  }
}
</style>
