<template>
  <div class="prices-inputs">
    <!-- List inputs -->
    <template
      v-if="getCategoryPrices && Object.keys(getCategoryPrices).length > 0"
    >
      <v-row dense>
        <v-col
          v-for="input in getCategoryPrices"
          :key="input.id"
          :cols="12 / Object.keys(getCategoryPrices).length"
        >
          <!-- input -->
          <v-text-field
            v-model.number="input.price"
            outlined
            dense
            :label="input.name"
            :hide-details="true"
            type="number"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <span class="text-center caption">Sin precio</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PricesInputs',
  props: {
    inputs: {
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      type: String,
      default: '',
      required: true
    }
  },
  computed: {
    /**
     * Obtiene los precios del producto
     * dependiendo de la categoría
     *
     * @return {object} - precios del producto
     */
    getCategoryPrices() {
      return this.category &&
        Object.keys(this.inputs).length &&
        this.inputs[this.category]
        ? this.inputs[this.category]
        : {}
    }
  }
}
</script>
