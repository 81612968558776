<template>
  <PanelSection title="Activar compañia" :hide-back-button="false">
    <div class="company-keys-form-menu">
      <v-container>
        <!-- Content Loading -->
        <ContentLoading :loading="!companyActivation" />
        <!-- Preview & Forms -->
        <template v-if="companyActivation">
          <v-row>
            <v-col cols="12" class="d-flex">
              <div class="mr-4">
                <v-btn small @click="handleToggleInfoDialog"
                  >Ver detalles</v-btn
                >
                <br />
                <v-btn small class="success mt-2" @click="openPreview"
                  >Vista previa</v-btn
                >
              </div>

              <CompanyActivationsPreview
                :url="companyActivation.placeUrl"
                :images="companyActivation.images"
              />
            </v-col>
            <v-col cols="12">
              <!-- Formulario de menus -->
              <CompanyActivationsMenus
                :place-id="placeId"
                @onChangeMenu="handleChangeMenu"
              />
              <!-- Formulario de categorías -->
              <CompanyActivationsCategories
                v-if="menuId"
                :menu-id="menuId"
                :place-id="placeId"
              />
              <v-row>
                <v-col cols="12" class="mt-2">
                  <FormButtons
                    accept-button-text="Terminar activación"
                    cancel-button-text="Añadir observación"
                    align-buttons="center"
                    @onClickAcceptButton="handleFinishActivation"
                    @onClickCancelButton="showDoneText = !showDoneText"
                  />
                </v-col>
                <v-col
                  v-show="showDoneText"
                  cols="12"
                  class="text-center pt-8 px-8 white"
                >
                  <v-textarea
                    v-model="doneText"
                    width="500px"
                    type="text"
                    label="Observaciones"
                    outlined
                    dense
                    color="grey"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
import {
  ACTIVATION_STATUS,
  ACTIVATION_TYPES,
  CARTA_URL_BAKARTA,
  CARTA_URL_SCHWEPPES,
  ROLES
} from '@/constants'
// Components
import PanelSection from '@/components/ui/PanelSection'
import ContentLoading from '@/components/ui/ContentLoading'
import CompanyActivationsPreview from '@/components/elements/companyActivations/CompanyActivationsPreview'
import CompanyActivationsCategories from '@/components/elements/companyActivations/CompanyActivationsCategories'
import CompanyActivationsMenus from '@/components/elements/companyActivations/CompanyActivationsMenus'
import InfoActivationCompany from '@/components/elements/companyActivations/InfoActivationCompany'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import {
  getPanelCompanyActivationById,
  updatePanelCompanyActivations
} from '@/services/panelCompanyActivations'
import { getPlaceByCompanyKeysId } from '@/services/place'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { getCurrentBrand } from '@/utils'

export default {
  name: 'CompanyActivationsFormMenu',
  components: {
    PanelSection,
    ContentLoading,
    CompanyActivationsPreview,
    CompanyActivationsCategories,
    CompanyActivationsMenus,
    FormButtons
  },
  mixins: [uiMixin],
  data() {
    return {
      id: this.$route.params.id || null,
      companyActivation: null,
      placeId: null,
      menuId: null,
      showDoneText: false,
      doneText: null
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData'])
  },
  async mounted() {
    await this.setEveryNeededData()
  },
  methods: {
    /**
     * Evento lanzado cuando cambiamos el menú
     * seleccionado
     *
     * @param {String} value - UID de menú (categoría padre)
     */
    handleChangeMenu(value) {
      this.menuId = value
    },
    /**
     * Show modal info
     */
    handleToggleInfoDialog() {
      this.modifyAppDialog({
        contentComponent: InfoActivationCompany,
        title: 'Información de la solicitud',
        optionsActionsButtons: {
          acceptButtonHide: true,
          cancelButtonText: 'Cerrar'
        },
        visible: true
      })
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(message) {
      this.modifyAppAlert({
        dismissibleTimeOut: -1,
        text: message,
        type: 'error',
        visible: true
      })
      // Redirect
      this.goToList()
    },
    /**
     * Marcamos como finalizada la activación
     */
    async handleFinishActivation() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          await updatePanelCompanyActivations({
            id: this.companyActivation.id,
            status: ACTIVATION_STATUS.done.value,
            type: ACTIVATION_TYPES.assistant.value,
            doneText: this.doneText
          })
          this.goToList()
        },
        actionButtonText: 'Finalizar',
        dismissibleTimeOut: -1,
        text: '¿Desea finalizar la carta?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async setEveryNeededData() {
      try {
        if (!this.id) throw new Error('No se indico una activación')

        const companyActivation = await getPanelCompanyActivationById(this.id)

        if (
          !companyActivation ||
          (companyActivation &&
            this.userData.role !== ROLES.admin.value &&
            (companyActivation.type !== ACTIVATION_TYPES.assistant.value ||
              (companyActivation.type === ACTIVATION_TYPES.assistant.value &&
                companyActivation.status === ACTIVATION_STATUS.done.value)))
        ) {
          throw new Error(
            'No puede acceder a la activación indicada o no existe'
          )
        }

        // Get place associated to "companyKeys"
        const place = await getPlaceByCompanyKeysId(
          companyActivation.companyKeysId
        )

        if (!place)
          throw new Error(
            'No existe un establecimiento asociado a la activación'
          )

        // Set every variable
        this.companyActivation = companyActivation
        this.placeId = place.id
      } catch (error) {
        this.handleError(error)
      }
    },
    /**
     * Abre una vista previa de la carta
     */
    openPreview() {
      const brand = getCurrentBrand(process.env.VUE_APP_BRAND)
      const domain =
        brand === 'bakarta' ? CARTA_URL_BAKARTA : CARTA_URL_SCHWEPPES
      const url = `${domain}/${this.placeId}`
      window.open(url, '_blank')
    },
    /**
     * Return to company activations list
     */
    goToList() {
      this.routerPushTo({
        path: `/company-activations`
      })
    }
  }
}
</script>
