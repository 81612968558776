// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class Dish extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    allergens: [], // alergens array (ids)
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    categories: {}, // Relation object (keys) // TODO BORRAR
    categoriesArray: [], // Relation array
    description: null,
    disabled: false,
    image: null,
    name: null,
    order: 0,
    prices: null
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}dishes`)
  }

  // Here you can extend Company with custom methods
}
