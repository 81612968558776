// Constans
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class Category extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    description: null,
    disabled: false,
    dishes: {}, // Relation object (keys)
    name: null,
    places: {}, // Relation object (keys)
    price: null,
    order: 0,
    parentId: null
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}categories`)
  }

  // Here you can extend Company with custom methods
}
