// Constans
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class Meta extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    metaFields: {}, // campos asociados a este "meta"
    model: null, // modelo asociado
    modelId: null, // UID del registro del modelo asociado
    name: null
  }

  // Translate (optional)
  fieldsToTranslate = [
    {
      id: 'name',
      label: 'Nombre',
      type: 'text'
    }
  ]

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}metas`)
  }

  // Here you can extend Company with custom methods
}
