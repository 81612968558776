<template>
  <div class="allergens-options">
    <v-row>
      <v-col cols="12" class="allergens-options-items">
        <div
          v-for="allergen in allergens"
          :key="allergen.id"
          class="allergens-options__item"
        >
          <v-checkbox
            v-model="selected"
            :value="allergen.id"
            :hide-details="true"
          >
            <template v-slot:label>
              <img :src="getPathAllergenImage(allergen.icon)" class="icon" />
              <span class="name">{{ allergen.name }}</span>
            </template>
          </v-checkbox>
        </div>
      </v-col>
    </v-row>
    <!-- action buttons -->
    <FormButtons
      accept-button-text="Seleccionar"
      :cancel-button-hide="true"
      @onClickAcceptButton="handleAcceptButton"
    />
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Services
import { getConfigById } from '@/services/config'
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'AllergensOptions',
  components: { FormButtons },
  data() {
    return {
      allergens: [],
      selected: []
    }
  },
  async created() {
    await this.setAllergens()
  },
  methods: {
    /**
     * Event triggered when click on "accept" button
     */
    handleAcceptButton() {
      // Este evento es lanzado para todo aquel componente
      // que ha sido incluido bajo el component "CustomDialog"
      this.$emit('onEventComponent', this.selected)
      this.selected = []
    },
    /**
     * Set the available allergens
     */
    async setAllergens() {
      const { data } = await getConfigById('allergens')
      this.allergens = data
    },
    /**
     * Get icon to draw allergen
     *
     * @param {string} icon
     * @return {string} path to icon
     */
    getPathAllergenImage(icon) {
      // Remove "img" from it is not neccesary and '"'
      let pathIcon = icon.replace(/\/img\//i, '')
      pathIcon = pathIcon.replace(/"/gim, '')
      return getPathImage(pathIcon)
    }
  }
}
</script>
<style lang="scss">
@import '@/theme/variables.scss';
.allergens-options {
  .allergens-options-items {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .allergens-options__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      .v-input--selection-controls {
        margin: 0;
        padding: 0;
        .v-input__slot {
          & > .v-input--selection-controls__input {
            display: none;
          }
          & > .v-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 85px;
            margin: 0 0.625rem 0.625rem 0;
            padding: 0.5rem;
            background: $theme_background;
            .icon {
              max-width: 25px;
              height: auto;
              margin-bottom: 0.5rem;
            }
            .name {
              text-align: center;
              font-size: 0.75rem;
              line-height: 0.85rem;
            }
          }
        }
        &.v-input--is-label-active {
          .v-input__slot {
            & > .v-label {
              background: $theme_color_light;
            }
          }
        }
      }
    }
  }
}
</style>
