// Utils
import { isNil, get } from 'lodash'
// Model
import Category from '@/firebase/models/category'
// Services
import { delEveryDishesById } from './dish'

const categoryDb = new Category()

/**
 * Create new category
 *
 * @param {object} data - category data
 */
export async function createCategory(data, id = null) {
  const category = await categoryDb.create(data, id)
  return category
}

/**
 * Get category by UID
 *
 * @param {string} id - UID category in database
 */
export async function getCategoryById(id) {
  const category = await categoryDb.read(id)
  return category
}

/**
 * Update category in database
 *
 * @param {object} data - category data
 */
export async function updateCategory(data) {
  const category = await categoryDb.update(data)
  return category
}

/**
 * Get all categories by UID
 *
 * @param {array} ids - UID category in database
 */
export async function getEveryCategoriesByIds(ids) {
  if (Array.isArray(ids)) {
    const categories = await Promise.all(
      ids.map(async id => {
        const category = await categoryDb.read(id)
        return category
      })
    )
    // se usa "sort" en la ordenación porque Firebase
    // no permite filtrar por un campo y ordenar por otro
    return categories
      .filter(category => category)
      .sort((a, b) => {
        return a.order - b.order
      })
  }
  return []
}

/**
 * Get categories by parentId
 *
 * @param {string} parentId - UID category in database
 */
export async function getCategoriesByParentId(parentId) {
  if (parentId) {
    const categories = await categoryDb.readAll({
      constraints: [['parentId', '==', parentId]],
      order: {
        field: 'order',
        direction: 'asc'
      },
      forceOrder: true
    })
    return categories
  }
  return []
}

/**
 * Remove the category by id and dishes associated
 *
 * @param {string} id - UID category in database
 */
export async function delCategoryById(id) {
  if (id) {
    const category = await getCategoryById(id)

    // Remove dishes
    if (
      category &&
      typeof category.dishes === 'object' &&
      category.dishes !== null &&
      Object.keys(category.dishes).length
    ) {
      const dishesIds = Object.keys(category.dishes).reduce(
        (sumDishes, dish) => {
          sumDishes.push(dish)
          return sumDishes
        },
        []
      )

      // Remove dishes
      await delEveryDishesById(dishesIds)
    }

    // Remove category
    await categoryDb.delete(id)
  }
}

/**
 * Remove the dish's reference in a category
 *
 * @param {string} categoryId - UID category in database
 * @param {string} dishId - UID dish in database
 */
export async function delDishInCategory(categoryId, dishId) {
  if (categoryId && dishId) {
    const category = await getCategoryById(categoryId)

    if (!isNil(category)) {
      // Remove dish's category
      const dishesIds = Object.keys(get(category, 'dishes', {})).filter(id => {
        return id !== dishId
      })

      // Update category
      await updateCategory({
        id: categoryId,
        dishes: dishesIds.reduce((sumDish, dish) => {
          sumDish[dish] = true
          return sumDish
        }, {})
      })
    }
  }
}

/**
 * Remove the menu (child categories and dishes)
 *
 * @param {string} id - UID category (menu) in database
 */
export async function delMenuById(id) {
  if (id) {
    // Obtenemos todos las categorías hijas
    const chidlCategories = await getCategoriesByParentId(id)

    // Eliminamos categorías hijas y platos asociados
    if (Array.isArray(chidlCategories)) {
      await Promise.all(
        chidlCategories.map(async category => {
          await delCategoryById(category.id)
        })
      )
    }

    // Finalmente eliminamos menú
    await delCategoryById(id)
  }
}
