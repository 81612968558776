// Model
import Meta from '@/firebase/models/meta'
import MetaField from '@/firebase/models/metaField'
// Utils
import { isNil } from 'lodash'

const metaDb = new Meta()
const metaFieldDb = new MetaField()

/**
 * Obtiene las raciones por defecto
 *
 * @return {object} - objeto con raciones por defecto
 */
export function getDefaultRations() {
  return [
    {
      id: '0',
      name: 'Precio único',
      price: null
    }
  ]
}
/**
 * Crea el registro de "rations" asociado al establecimiento
 *
 * @param {string} placeId - UID place en la base de datos
 */
export async function createMetaRation(placeId) {
  if (placeId) {
    const ration = await metaDb.create({
      model: 'place',
      modelId: placeId,
      name: 'rations'
    })

    return ration
  }
  return false
}
/**
 * Obtiene el "meta" registro de "rations"
 *
 * @param {string} placeId - UID place en la base de datos
 */
export async function getMetaRationByPlaceId(placeId) {
  if (placeId) {
    const metaRations = await metaDb.readAll({
      constraints: [
        ['model', '==', 'place'],
        ['modelId', '==', placeId],
        ['name', '==', 'rations']
      ],
      limit: 1
    })
    return metaRations.length > 0 ? metaRations[0] : null
  }
  return null
}
/**
 * Obtiene todos los datos de raciones dado un array
 * de identificadores (UID) de raciones (metaField)
 *
 * @param {array} ids - array de identificadores (UID's)
 * @return {array} - todos los datos de las raciones
 */
export const getEveryRationsByIds = async ids => {
  if (Array.isArray(ids) && ids.length) {
    const rations = await Promise.all(
      ids.map(async id => {
        const ration = await metaFieldDb.read(id)
        return ration
      })
    )

    // Ordeno por "order"
    return rations.sort((a, b) => a.order - b.order)
  }
  return []
}
/**
 * Obtiene las raciones de un establecimiento
 *
 * @param {string} placeId - UID place en la base de datos
 */
export async function getRationsByPlaceId(placeId) {
  if (placeId) {
    let metaRation = await getMetaRationByPlaceId(placeId)

    if (isNil(metaRation)) {
      // Creamos meta-ración
      metaRation = await createMetaRation(placeId)
    }

    return await getEveryRationsByIds(Object.keys(metaRation.metaFields))
  }
  return []
}
/**
 * Actualiza la meta-ración (añadimos hijos, raciones)
 *
 * @param {object} data - datos a salvar
 */
export const updateMetaRation = async data => {
  await metaDb.update(data)
}
/**
 * Crea una nueva ración en DB
 *
 * @param {object} data - datos a salvar
 * @param {string} placeId - UID establecimiento
 * @param {string} rationId - UID de nueva ración
 * @return {object} data - datos salvados
 */
export const createRation = async (data, placeId, rationId) => {
  const defaultRationData = {
    order: 0,
    price: null
  }
  const { id } = await getMetaRationByPlaceId(placeId)
  // create new ration (metaField)
  const ration = await metaFieldDb.create(
    {
      ...defaultRationData,
      ...data,
      metas: {
        [id]: true
      }
    },
    rationId
  )

  return ration
}
