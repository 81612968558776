// Database
import Dish from '@/firebase/models/dish'
// Services
import { updateCategory, getCategoryById, delDishInCategory } from './category'

const dishDb = new Dish()

/**
 * Get all dishes by UID category
 *
 * @param {array} id - UID category
 */
export const getEveryDishesByCategory = async id => {
  if (id) {
    const dishes = await dishDb.readAll({
      constraints: [['categoriesArray', 'array-contains', id]],
      order: {
        field: 'order',
        direction: 'asc'
      },
      forceOrder: true,
      limit: null
    })

    return dishes
  }
  return []
}

/**
 * Get all dishes by id (in array)
 *
 * @param {array} ids - UID dishe in database
 */
export const getEveryDishesByIds = async ids => {
  if (Array.isArray(ids)) {
    const dishes = await Promise.all(
      ids.map(async id => {
        const dish = await dishDb.read(id)
        return dish
      })
    )

    return dishes.sort((a, b) => a.order - b.order)
  }
  return []
}
/**
 * Create N new dishes automatically
 *
 * @param {string} name - Product name
 * @param {string} categoryId - Category id
 * @param {numeric} numDish - Dish number to create
 * @param {numeric} startAt - Counter starts at...
 */
export const createSomeDishes = async (
  name = 'Producto',
  categoryId,
  numDish = 10,
  startAt = 0
) => {
  const requests = []
  const endAt = startAt + numDish

  // Create dishes
  for (let i = startAt; i < endAt; i += 1) {
    requests.push(
      await dishDb.create({
        name: `${name} ${i + 1}`,
        categories: {
          [categoryId]: true
        }, // TODO BORRAR
        categoriesArray: [categoryId],
        order: i
      })
    )
  }
  const categoryData = await getCategoryById(categoryId)
  const dishes = await Promise.all(requests)
  const dishesIds = dishes.reduce((sumDishes, dish) => {
    sumDishes[dish.id] = true
    return sumDishes
  }, categoryData.dishes || {})

  // Update category associated
  await updateCategory({ dishes: dishesIds, id: categoryId })

  return dishes
}
/**
 * Update some dishes in database
 *
 * @param {object} data - dishes data
 */
export const updateSomeDishes = async data => {
  if (Array.isArray(data)) {
    const dishes = await Promise.all(
      data.map(async dish => {
        const dishUpdated = await dishDb.update(dish)
        return dishUpdated
      })
    )
    return dishes
  }
  return []
}
/**
 * Get dish by UID
 *
 * @param {string} id - UID dish in database
 */
export const getDishById = async id => {
  const dish = await dishDb.read(id)
  return dish
}
/**
 * Remove the dish by id
 *
 * @param {string} id - UID dish in database
 */
export const delDishById = async id => {
  if (id) {
    const dishData = await getDishById(id)
    const categoryId = dishData.categoriesArray[0]

    await delDishInCategory(categoryId, id)
    await dishDb.delete(id)
  }
}
/**
 * Remove all dishes by ids
 *
 * @param {array} dishesIds - ids dishes
 */
export async function delEveryDishesById(dishesIds) {
  if (Array.isArray(dishesIds)) {
    await Promise.all(
      dishesIds.map(async id => {
        await delDishById(id)
      })
    )
  }
}
